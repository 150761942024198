import React from 'react';
import axios from 'axios';
import qs from 'qs';

class FuelForm extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            date: "",
            station: "",
            vehicle_id: "",
            price: "",
            litres: "",
            ppl: "",
            trip: "",
            mileage: ""
        };
    }

    changeHandler = e => {
        this.setState({[e.target.name]: e.target.value});
    }

    formSubmission = e => {
        e.preventDefault();
        console.log(this.state);
        axios.post("http://fuel.tecserv-uk.co.uk/save.php", qs.stringify(this.state)
        ).then( response => {
            console.log( response.data );
            alert("Saved!");
        }).catch( response => {
            console.log( response );
            alert("Error!");
        });
    }
    render() {
        return (
            <form onSubmit={this.formSubmission}>
                <h1>Fuel Form</h1>
                <label>Date</label>
                <input type="date" name="date" onChange={this.changeHandler}></input>
                <label>Petrol Station</label>
                <input type="text" name="station" onChange={this.changeHandler}></input>
                <label>Vehicle</label>
                <select name="vehicle_id" onChange={this.changeHandler}>
			        <option value="1">Nissan Micra</option>
                    <option value="2">Midget</option>
                    <option value="3">Mercedes Sprinter</option>
                </select>
                <label>Price</label>
                <input type="number" name="price" onChange={this.changeHandler}></input>
                <label>Litres</label>
                <input type="number" name="litres" onChange={this.changeHandler}></input>
                <label>Trip Mileage</label>
                <input type="number" name="trip" onChange={this.changeHandler}></input>
                <label>Total Mileage</label>
                <input type="number" name="mileage" onChange={this.changeHandler}></input>
                <label>Pence Per Litre</label>
                <input type="number" name="ppl" onChange={this.changeHandler}></input>
                <br></br>
                <input type="submit" value="save"></input>
            </form>
        );
    }
}

export default FuelForm;
